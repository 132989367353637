import React, { Component } from 'react';
import Popup from "reactjs-popup";
import { Link, withRouter } from 'react-router-dom';
import { withFirebase } from '../Firebase';
import { compose } from 'recompose';
 
import * as ROUTES from '../../constants/routes';

import '../../css/index.css'
 
const INITIAL_STATE = {
  email: '',
  password: '',
  error: null,
};

const SignInPage = () => (
  <div>
    <h1>Sign In</h1>
    <SignInForm />
  </div>
);
 
class SignInFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }
 
  onSubmit = event => {
    const {email, password } = this.state;
 
    this.props.firebase
      .doSignInWithEmailAndPassword(email, password)
      .then(authUser => {
        this.setState({ ...INITIAL_STATE });
        this.props.history.push(ROUTES.HOME);
      })
      .catch(error => {
        this.setState({ error });
      });
 
    event.preventDefault();
  }
 
  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };
 
  render() {
    const {
      email,
      password,
      error,
    } = this.state;

    const isInvalid =
      password === '' ||
      !email.match(/^\S+@\S+\.\S+$/g);

    return (
      <form onSubmit={this.onSubmit}>
        <input
          name="email"
          value={email}
          onChange={this.onChange}
          type="text"
          placeholder="Email Address"
        />
        <input
          name="password"
          value={password}
          onChange={this.onChange}
          type="password"
          placeholder="Password"
        /> 

        <button disabled={isInvalid} type="submit">Sign In</button>
 
        {error && <p>{error.message}</p>}
      </form>
    );
  }
}

class SignInPopUpBase extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }
 
  onSubmit = event => {
    const {email, password } = this.state;
 
    this.props.firebase
      .doSignInWithEmailAndPassword(email, password)
      .then(authUser => {
        this.setState({ ...INITIAL_STATE });
      })
      .catch(error => {
        this.setState({ error });
      });
 
    event.preventDefault();
  }
 
  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };
 
  render() {
    const {
      email,
      password,
      error,
    } = this.state;

    const isInvalid =
      password === '' ||
      !email.match(/^\S+@\S+\.\S+$/g);

    return (
      <Popup trigger={<p className="nav_button">Sign In</p>}
        modal
        closeOnEscape
      >
        {close => (
          <div className="sign-in">
            <form onSubmit={this.onSubmit} >
              <input
                name="email"
                value={email}
                onChange={this.onChange}
                type="text"
                placeholder="Email Address"
              />
              <input
                name="password"
                value={password}
                onChange={this.onChange}
                type="password"
                placeholder="Password"
              /> 

              <button disabled={isInvalid} type="submit">Sign In</button>
 
              {error && <p>{error.message}</p>}
            </form>
          </div>
        )}
      </Popup>
    );
  }
}
 
const SignInLink = () => (
  <p>
    <Link to={ROUTES.SIGN_IN}>Sign In</Link>
  </p>
);

const SignInForm = compose(
  withRouter,
  withFirebase,
)(SignInFormBase);
const SignInPopUp =  compose(
  withRouter,
  withFirebase,
)(SignInPopUpBase);
 
export default SignInPage;
 
export { SignInForm, SignInPopUp, SignInLink };
