import React, { Component } from 'react';

import Popup from "reactjs-popup";

// import { Link } from 'react-router-dom';
// import { withFirebase } from '../Firebase';
import { basicCondition, withAuthorization } from '../Session';
 
import '../../css/index.css';
import './edit_tag.css';

import * as ROUTES from '../../constants/routes';
 
class EditTagPage extends Component {
    constructor(props) {
        super(props);
        
        this.newTag = props.newTag;
        this.state = {
            body: [],
            color: ['#000000','#FFFFFF'],
            description: '',
            displayName: '',
            logs: [],
            name: '',
            tid: (props.newTag? null : props.match.params.tid),
            error: null,
            loading: false,
            tag: null,
            logName: '',
        };
    }
   
    componentDidMount() {
        if (this.state.tid !== null && !this.newPost) {
          this.setState({ loading: true });
  
          this.props.firebase
            .tag(this.state.id)
            .get()
            .then(doc => {
              let data = doc.data();
              this.setState({
                body: data.body,
                color: '#000000',
                description: data.description,
                displayName: data.displayName,
                logs: data.logs,
                name: data.name,
                loading: false,
                tag: data,
              });
            });
        }
    }
  
    onSubmit = event => {
        const { body, color, description, displayName, logs, name } = this.state;

        if (this.newTag) {
            this.props.firebase.tags()
              .add({ body, color, description, displayName, logs, name })
              .then(docRef => {
                console.log(`Tag Document written with ID: ${docRef.id}`);
                this.props.history.push({pathname: ROUTES.TAG_PAGE+`/${docRef.id}`});
              })
              .catch(error => {
                console.log(`Error: ${error}`)
                this.setState({ error });
              });
        } else {
            this.props.firebase.tags(this.state.id)
              .set({ body, color, description, displayName, logs, name })
              .then(() => {
                console.log(`Tag Document written with ID: ${this.state.tid}`);
                this.props.history.push({pathname: ROUTES.TAG_PAGE+`/${this.state.tid}`});
              })
              .catch(error => {
                console.log(`Error: ${error}`)
                this.setState({ error });
              });
        }
  
        event.preventDefault();
    }
  
    onCancel = event => {
        if (this.props.history.length > 0 && !this.newTag){
          this.props.history.push({pathname: ROUTES.TAG_PAGE+`/${this.state.tid}`});
        } else {
          this.props.history.push(ROUTES.TAG_PAGE);
        }
    }
  
   
    onChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    }

    changeColor = event => {
        let color = [...this.state.color];
        color[Number(event.target.name)] = event.target.value;
        this.setState({ color });
    }
   
    changeBody = event => {
        let body = [...this.state.body];
        body[event.target.name].content = event.target.value;
        this.setState({ body });
    }

    changeLog = event => {
        let logs = [...this.state.logs];
        logs[event.target.name].value = event.target.value;
        this.setState({ logs });
    }

    nameForm = () => {
        const { name, color } = this.state;
        return (
            <textarea
              className="name_form"
              style={{background: color[0], color: color[1]}}
              name="name"
              value={name}
              onChange={this.onChange}
              type="text"
              placeholder="Name"
            />
        );
    }

    displayNameForm = () => {
        const { displayName } = this.state;
        return (
            <textarea
              className="post_body"
              name="displayName"
              value={displayName}
              onChange={this.onChange}
              type="text"
              placeholder="Display Name"
            />
        );
    }

    colorForm = () => {
        const { color } = this.state;
        return (
            <div>
                <textarea
                  className="post_body"
                  name="0"
                  value={color[0]}
                  onChange={this.changeColor}
                  type="text"
                  placeholder="Background Color"
                />
                <textarea
                  className="post_body"
                  name="1"
                  value={color[1]}
                  onChange={this.changeColor}
                  type="text"
                  placeholder="Text Color"
                />
            </div>
        );
    }

    descriptionForm = () => {
        const { description } = this.state;
        return (
            <textarea
              className="post_body"
              name="description"
              value={description}
              onChange={this.onChange}
              type="text"
              placeholder="Description"
            />
        );
    }

    logForm = () => {
        const { logs } = this.state;
        return (logs.map( (log, index) => { 
            return (
              <div key={index}>
                {log.name}:
                <textarea
                  className="post_body"
                  name={index}
                  value={log.value}
                  onChange={this.changeLog}
                  type="number"
                  placeholder="Number of events"
                />
                <button type="button" onClick={() => this.removeEvent(index)} >-</button>
              </div>
            )
        }));
    }

    addLogPopup = () => {
        const { logName } = this.state;
        return (
            <Popup trigger={<button type="button">+</button>} position="right top" closeOnEscape >
              { close => (
                <div>
                  <textarea
                    className="post_body"
                    name="logName"
                    value={logName}
                    onChange={this.onChange}
                    type="text"
                    placeholder="Log Name"
                  />
                  <button type="button" onClick={() => {this.addLog(logName); close()}} >Add Log</button>
                </div>
              )}
            </Popup>
        );
    }

    addLog = name => {
        let logs = [...this.state.logs];
        logs.push({name:name, value:0});
        this.setState({ logs });
    }
    
    removeLog = index => {
        let logs = [...this.state.logs];
        logs.splice(index, 1);
        this.setState({ logs });
    }

    bodyForm = () => {
        const { body } = this.state;
        return (body.map( (element, index) => { 
                  if (element.type === 'text') {
                    return (
                      <div key={index}>
                        <textarea
                          className="post_body"
                          name={index}
                          value={element.content}
                          onChange={this.changeBody}
                          type="text"
                          placeholder="Write something here..."
                        />
                        <button type="button" onClick={() => this.removeElement(index)} >-</button>
                      </div>
                    );
                  } else if (element.type === 'img') {
                    return (
                      <div key={index}>
                        <img src={element.content} alt="img" style={{boxSizing: "border-box", padding: "0px 20%", width: "100%", height:"auto"}} />
                        <input
                          key={index}
                          name={index}
                          value={element.content}
                          onChange={this.changeBody}
                          type="text"
                          placeholder="image url"
                        /> 
                        <button type="button" onClick={() => this.removeElement(index)} >-</button>
                      </div>
                    );
                  } else { return <p>Invalid Type: {element.type}</p> }
                })
        );
    }

    addElementPopup = () => {
        return (
            <Popup trigger={<button type="button">+</button>} modal closeOnEscape >
              { close => (
                <div>
                  <button type="button" onClick={() => {this.addElement('text'); close()}} >Text</button>
                  <button type="button" onClick={() => {this.addElement('img'); close()}} >Image</button>
                  <button type="button" onClick={close} >Cancel</button>
                </div>
              )}
            </Popup>
        );
    }

    addElement = type => {
        let body = [...this.state.body];
        body.push({type:type, content:""});
        this.setState({ body: body });
    }
    
    removeElement = index => {
        let body = [...this.state.body];
        body.splice(index, 1);
        this.setState({ body: body });
    }
  
    render() {
        const { body, name, tag, error, loading } = this.state;

        const isInvalid = name === '' || body.length < 1;
   
        if ((typeof tag === "undefined" || tag === null || loading) && !this.newTag) {
          return (<div>{loading && <div>Loading ...</div>}</div>);
        } else {
          return (
            <form onSubmit={this.onSubmit}>
              <button disabled={isInvalid} type="submit">{this.newTag?"Submit":"Save Changes"}</button>
              <button onClick={this.onCancel}>Cancel</button>
              <br/>
              {this.nameForm()}
              {this.displayNameForm()}
              {this.colorForm()}
              {this.descriptionForm()}
              {this.logForm()}
              {this.addLogPopup()}
              {this.bodyForm()}
              {this.addElementPopup()}
              {error && <p>{error.message}</p>}
            </form>
          );
        }
    }
}
EditTagPage.defaultProps = {
    newPost: false,
}
 
export default withAuthorization(basicCondition)(EditTagPage); 
