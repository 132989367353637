import React from 'react';

const game = () => { 
    return <div>Game 4 DIV</div>
};


export const GameInfo = {
    name: "G4",
    initial: {
        g4: "g4",
        type: "G4",
        players:[],
    }
}

export default game;
