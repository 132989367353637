/*
<!-- The core Firebase JS SDK is always required and must be listed first -->
<script src="https://www.gstatic.com/firebasejs/7.15.1/firebase-app.js"></script>

<!-- TODO: Add SDKs for Firebase products that you want to use
     https://firebase.google.com/docs/web/setup#available-libraries -->

<script>
  // Your web app's Firebase configuration
  var firebaseConfig = {
    apiKey: "AIzaSyCS_pi57OTFxwdQj0xE--REzkCJXcN8Kpo",
    authDomain: "acramer-webhost.firebaseapp.com",
    databaseURL: "https://acramer-webhost.firebaseio.com",
    projectId: "acramer-webhost",
    storageBucket: "acramer-webhost.appspot.com",
    messagingSenderId: "170560267937",
    appId: "1:170560267937:web:0dd7a6f71aacb16dab72e0"
  };
  // Initialize Firebase
  firebase.initializeApp(firebaseConfig);
</script>
*/

import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';


const config = {
  apiKey: "AIzaSyCS_pi57OTFxwdQj0xE--REzkCJXcN8Kpo",
  authDomain: "acramer-webhost.firebaseapp.com",
  databaseURL: "https://acramer-webhost.firebaseio.com",
  projectId: "acramer-webhost",
  storageBucket: 'acramer-webhost.appspot.com',
  messagingSenderId: "1:170560267937:web:0dd7a6f71aacb16dab72e0",
};

class Firebase {
  constructor() {
    app.initializeApp(config);

    this.fieldValue = app.firestore.FieldValue;
 
    this.auth = app.auth();
    this.db = app.firestore();
    this.Timestamp = app.firestore.Timestamp;
  }
 
  // *** Auth API ***
 
  doCreateUserWithEmailAndPassword = (email, password) =>
    this.auth.createUserWithEmailAndPassword(email, password);
 
  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password); 

  doSignOut = () => this.auth.signOut();

  doPasswordReset = email => this.auth.sendPasswordResetEmail(email);
 
  doPasswordUpdate = password =>
    this.auth.currentUser.updatePassword(password);

 
  // *** User API ***
 
  user = uid => this.db.doc(`users/${uid}`);
 
  users = () => this.db.collection('users');

  //
  
  post = pid => this.db.doc(`posts/${pid}`);

  posts = () => this.db.collection('posts');

  //
  
  tag = tid => this.db.doc(`tags/${tid}`);

  tags = () => this.db.collection('tags');

  //
  
  game = gid => this.db.doc(`games/${gid}`);

  games = () => this.db.collection('games');

  gameInfo = () => this.db.doc('games/2CDlz2dVik5RKWYDBxRu');

  //

  player = playid => this.db.doc(`players/${playid}`);

  players = () => this.db.collection('players');

  //

  project = projectid => this.db.doc(`projects/${projectid}`);

  projects = () => this.db.collection('projects');

  //

  experience = experienceid => this.db.doc(`experiences/${experienceid}`);

  experiences = () => this.db.collection('experiences');

  //

  pageInfo = pageInfoid => this.db.doc(`pageInfo/${pageInfoid}`);

  pageInfos = () => this.db.collection('pageInfo');
}


export default Firebase;
