import React from 'react';
import './index.css';

import Card from '@material-ui/core/Card';
import Hidden from '@material-ui/core/Hidden';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';

// import Icon from '@mdi/react';
// import { mdiGithub } from '@mdi/js';

import PageModal from '../PageModal';

class ProjectCard extends React.Component{
    constructor(props){
        super(props);
        this.state = {
          raised:false
        }

        this.project = {}
        if (this.props.project){
            this.project = this.props.project
        }

        this.title = this.project.title;
        this.summary = this.project.summary;
        this.body = this.project.body;
        this.date = this.project.date;
        this.img = this.project.img;
        this.gif = this.project.gif;
        this.url = this.project.url;
        this.primaryColor = this.project.primaryColor;
        this.secondaryColor = this.project.secondaryColor;
        this.toggleRaised = this.toggleRaised.bind(this)
    }

    toggleRaised(){
        this.setState({raised:!this.state.raised});
    }

    render(){
        return (
          <PageModal title={this.title} color={this.primaryColor} 
            url={this.url}
            action={
              <div>View Project Repo</div>
              /*<div>
                <div>View on Github</div>
                <Icon path={mdiGithub}
                    size={1}
                    color="black"/>
              </div>}
              */
              }
            content={
            <div>
              <DialogContent dividers>
                <Typography gutterBottom>
                  {this.body}
                </Typography>
              </DialogContent>
            </div>
          }>
            <Card onMouseEnter={this.toggleRaised} onMouseLeave={this.toggleRaised} raised={this.state.raised} className='project-card' >
              {(this.img && this.gif) && <div className='card-image-container'><img src={this.img} alt=' '/></div>}
              {(this.img && this.gif) ?
                <div className='card-image-container'>
                  <Hidden only={this.state.raised ? [] : ['xs','sm','md','lg','xl']}><img src={this.gif} alt=' '/></Hidden>
                </div>
                : this.img && <div className='card-image-container'><img src={this.img} alt=' '/></div>
              }
              <div className='project-content' >
                <h3 style={{color:this.primaryColor}}>{this.title}</h3>
                <h6 style={{color:this.secondaryColor}}>{this.date}</h6>
                <div style={{padding:'15px',fontSize:'22px'}} >{this.summary}</div>
                <div>{this.props.children}</div>
              </div>
              <Hidden only={this.state.raised ? [] : ['xs','sm','md','lg','xl']}>
                <div className='project-selection' style={{border: '3px solid '+this.primaryColor}}>
                  <div style={{color:this.primaryColor,fontWeight:'bold',fontSize:'13px',position:'absolute',bottom:'25px'}}>More Info</div>
                </div>
              </Hidden>
            </Card>
          </PageModal>
        );

    }
}

export default ProjectCard;
