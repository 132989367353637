import React from 'react';
import './index.css';

import FadeIn from 'react-fade-in';

class PageSelection extends React.Component{
    render(){
        return(
            <div style={{backgroundColor:this.props.color}} className="landing-section">
              <FadeIn>
                {this.props.children}
              </FadeIn>
            </div>
        );
    }
}

export default PageSelection;
