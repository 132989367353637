import React from 'react';
import './index.css';
import headshot from './headshot.jpg' 
import StackGrid from "react-stack-grid";

import { withFirebase } from '../Firebase';
// import { Link } from 'react-router-dom';
// import * as ROUTES from '../../constants/routes';

// import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { ArrowForward } from '@material-ui/icons';

import NavButton from '../NavButton';
import PageSection from '../PageSection';
import ProjectCard from '../ProjectCard';
import ExperienceAccordian from '../ExperienceAccordian';

import { AuthUserContext } from '../Session';

class LandingPage extends React.Component {
    constructor(props) {
      super(props);
 
      this.state = {
        loading: false,
      };
    }

    render() {
      return (
        <div className="App">

          {/*Nav Button*/}
          <NavButton/>

          {/*1.About Section*/}
          <PageSection color='#FFFFFF'>
            <LandingAbout/>
          </PageSection>

          {/*2.Projects*/}

          {/*<div>Loading: {this.state.loading.toString()}</div>*/}
          <PageSection color='#F4F8F6'>
            <h3>Projects</h3>
            <AuthUserContext>
              {authUser => authUser && <Button variant="contained" style={{backgroundColor:'#FFFFFF'}}>Edit Section</Button> }
            </AuthUserContext>
            <LandingProject/>
            <Button variant="contained" style={{backgroundColor:'#FFFFFF'}} href='/projects' >More projects <ArrowForward/></Button>
          </PageSection>

          {/*3.Experience*/}
          <PageSection color='#FFFFFF'>
            <h3>Experience</h3>
            <AuthUserContext>
              {authUser => authUser && <Button variant="contained" style={{backgroundColor:'#FFFFFF'}}>Edit Section</Button> }
            </AuthUserContext>
            <LandingExperience/>
          </PageSection>

          {/*4.Interests*/}
          <PageSection color='#F4F8F6'>
            <h3>Interests - Coming Soon</h3>
          </PageSection>

        </div>
      );
    }
}

class LandingAboutBase extends React.Component {
    constructor(props) {
      super(props);
 
      this.state = {
        about: '',
        loading: false,
      };
    }

    componentDidMount() {
      this.setState({ loading: true });

      this.props.firebase
        .pageInfo('landing')
        .get()
        .then(doc => {
          let about = doc.data()['about'];

          this.setState({
            about,
            loading: false,
          });
      });
    }

    render() {
      const { about } = this.state;

      return (
        <div className='landing_block'>
          <div className='landing_about'>{about}</div>
          <img className='landing_image' src={headshot} alt='Headshot'/>
        </div>
      );
    }
} 

const LandingAbout = withFirebase(LandingAboutBase);

class LandingProjectBase extends React.Component {
    constructor(props) {
      super(props);
 
      this.state = {
        loading: false,
        projects: [],
      };
    }
 
    componentDidMount() {
      this.setState({ loading: true });

      this.unsubscribe = this.props.firebase
        .projects()
        .onSnapshot(snapshot => {
          // let projects = [];
          //
          // snapshot.forEach(doc => 
          //   projects.push({ ...doc.data(), id: doc.id }),
          // );

          // TESTING  
          let all_projects = {};
          snapshot.forEach(doc => 
            all_projects[doc.id] = { ...doc.data(), id: doc.id },
          );
          console.log('All Projects:');
          console.log(all_projects);

          this.props.firebase
            .pageInfo('landing')
            .get()
            .then(doc => {
                let projects = [];

                let landing_project_ids = doc.data().landprojects
                console.log('LP IDs:');
                console.log(landing_project_ids);
                for (let lpid = 0; lpid < landing_project_ids.length; lpid++) {
                    if (landing_project_ids[lpid] in all_projects) {
                        projects.push(all_projects[landing_project_ids[lpid]])
                    }
                }
                console.log('Projects:');
                console.log(projects);

                this.setState({
                  projects,
                  loading: false,
                });
            });
          // TESTING END 
          //console.log(projects);

          // this.setState({
          //   projects,
          //   loading: false,
          // });
      });
    }

    render() {
      const { projects } = this.state;

      return (
        <StackGrid
          columnWidth={400}
          gutterWidth={10}
          gutterHeight={10}
          style={{padding:'0px 0px 20px 0px'}}
        >
          {projects.map(project => (
            <ProjectCard key={project.id} project={project}/>
          ))}
        </StackGrid>
      );
    }
}
const LandingProject = withFirebase(LandingProjectBase);


class LandingExperienceBase extends React.Component {
    constructor(props) {
      super(props);
 
      this.state = {
        loading: false,
        expanded: false,
        experiences: [],
      };
    }

    componentDidMount() {
      this.setState({ loading: true });

      this.unsubscribe = this.props.firebase
        .experiences()
        .onSnapshot(snapshot => {
          let all_experiences = {};
          snapshot.forEach(doc => 
            all_experiences[doc.id] = { ...doc.data(), id: doc.id },
          );
          this.props.firebase
            .pageInfo('landing')
            .get()
            .then(doc => {
                let experiences = [];

                let selected_ids = doc.data().landexperiences
                for (let sid = 0; sid < selected_ids.length; sid++) {
                    if (selected_ids[sid] in all_experiences) {
                        experiences.push(all_experiences[selected_ids[sid]])
                    }
                }

                this.setState({
                  experiences,
                  loading: false,
                });
            });
      });
    }
 
    //componentDidMount() {
    //  this.setState({ loading: true });

    //  this.unsubscribe = this.props.firebase
    //    .experiences()
    //    .onSnapshot(snapshot => {
    //      let experiences = [];
    //      
    //      snapshot.forEach(doc => 
    //        experiences.push({ ...doc.data(), id: doc.id }),
    //      );

    //      this.setState({
    //        experiences,
    //        loading: false,
    //      });
    //  });
    //}

    render() {
      const handleChange = (panel) => (event, newExpanded) => {
        this.setState({ expanded: (newExpanded ? panel : false) })
      };

      const { experiences, expanded } = this.state;

      return (
        <div>
          {experiences.map(experience => (
            <div><ExperienceAccordian expanded={expanded === 'panel'+experience.id} handleChange={handleChange('panel'+experience.id)} experience={experience}/></div>
          ))}
        </div>
      );
    }
}
const LandingExperience = withFirebase(LandingExperienceBase);

//export default withFirebase(LandingPage);
export default LandingPage;
