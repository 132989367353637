import React, { Component } from 'react';

// import { Link } from 'react-router-dom';

import { withFirebase } from '../Firebase';
// import { withCookies, Cookies } from 'react-cookie';
import { withCookies } from 'react-cookie';
import { compose } from 'recompose';

import '../../css/index.css';
// import './index.css';

// import * as ROUTES from '../../constants/routes';
import * as GAMES from './Games/games';

class GamePage extends Component {
    constructor(props) {
        super(props);
        this.gid = this.props.match.params.gid;
        this.state = {
            gameType: '',
            username: this.props.cookies.get('user') || '',
            playid: '',
            verified: false,
            passwordValue: "",
            loading: false,
        }

        this.displayGameVerification = this.displayGameVerification.bind(this);
        this.displayGame = this.displayGame.bind(this);
        this.leaveGame = this.leaveGame.bind(this);
    }
    
    componentDidMount() {
        this.setState({loading:true});
        
        this.props.firebase.game(this.gid).get()
          .then(doc => {
              let gameType = "";
              if (doc) {
                gameType = doc.data().type;
              }
              this.setState({gameType: gameType});
              this.verifyPassword(this.props.cookies.get('acramer-player'));
          })
    }

    componentWillUnmount() {
        // Deactivate Player
    }

    onChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    }

    getGame(type) {
        if (type !== "" && typeof GAMES[type] !== "undefined") {
            if (this.state.playid !== '' && this.gid !== '' && this.state.username !== ''){
                const GAME = GAMES[type];
                return <GAME playid={this.state.playid} gid={this.gid} username={this.state.username} leaveGame={this.leaveGame} />
            } else {
                return <div>loading...</div>
            }
        } else {
            return <GAMES.Default/>
        }
    }

    verifyPassword(UP_combo) {
        if (Array.isArray(UP_combo)) {
            const [username, password, playid] = UP_combo;
            this.setState({loading:true});
            this.props.firebase.game(this.gid).get()
              .then(doc => {
                if (password === doc.data().password) {
                    // Set Cookie
                    let date = new Date();
                    date.setDate(date.getDate() + 1);
                    
                    if (!playid) {
                        this.props.firebase.players().add({active: true, username: username})
                          .then( docRef => {
                            this.props.cookies.set('acramer-player',[username, password, docRef.id],
                                {  path: "acramer.com/",
                                   expires: date,   
                                   sameSite: true,
                                })
                            this.setState({playid: docRef.id, username: username, verified: true, loading: false})
                          })
                    } else {
                        this.props.cookies.set('acramer-player',[username, password, playid],
                            {  path: "acramer.com/",
                               expires: date,   
                               sameSite: true,
                            })
                        this.setState({playid: playid, username: username, verified: true, loading: false})
                    }
                } else {
                    this.props.cookies.remove('acramer-player')
                    this.setState({loading:false});
                }
              })
        } else {
            this.setState({loading:false});
        }
    }

    displayGameVerification() {
        const {username, passwordValue} = this.state;
        return ( 
            <div>
                <h1>Verification</h1>
                <p>Type in a username and the game password.</p>
                <input
                  name={"username"}
                  value={username}
                  onChange={this.onChange}
                  type="text"
                  placeholder="username"
                />
                <input
                  name={"passwordValue"}
                  value={passwordValue}
                  onChange={this.onChange}
                  type="text"
                  placeholder="password"
                />
                <button onClick={()=>this.verifyPassword([username, passwordValue, ''])} >Submit</button>
            </div>
        );
    }
    
    displayGame() {
        const {gameType} = this.state;
        return this.getGame(gameType);
    }

    leaveGame() {
        this.props.firebase.player(this.playid).delete();
        this.props.cookies.remove('acramer-player');
        this.setState({ verified: false });
    }

    render() {
        const { gameType, loading } = this.state;
        if (!loading && gameType) {
            return this.state.verified ? this.displayGame() : this.displayGameVerification();
        } else {
            return loading ? <div>Loading...</div> : <div>Game Not Found</div>
        }
    }
}

export default compose(withFirebase, withCookies)(GamePage);
