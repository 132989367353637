import BatImg from './Bat.png';
import FlyImg from './Fly.png';
import CockroachImg from './Cockroach.png';
import ToadImg from './Toad.png';
import RatImg from './Rat.png';
import ScorpionImg from './Scorpion.png';
import SpiderImg from './Spider.png';
import StinkBugImg from './StinkBug.png';

export const Bat = BatImg;
export const Fly = FlyImg;
export const Cockroach = CockroachImg;
export const Toad = ToadImg;
export const Rat = RatImg;
export const Scorpion = ScorpionImg;
export const Spider = SpiderImg;
export const StinkBug = StinkBugImg;
