import React, { Component } from 'react';
 
// import Popup from "reactjs-popup";
import Autosuggest from 'react-autosuggest';

import { Link } from 'react-router-dom';

import { withFirebase } from '../Firebase';
import { AuthUserContext } from '../Session';

import * as ROUTES from '../../constants/routes';
import GAME_INFO from './Games/game_info';

import '../../css/index.css';
//import './index.css';

class JoinGamePage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            value: "",
            passwordValue: "",
            suggestions: [],
        };

        this.onChange = this.onChange.bind(this);
        this.onSuggestionsFetchRequested = this.onSuggestionsFetchRequested.bind(this);
        this.onSuggestionsClearRequested = this.onSuggestionsClearRequested.bind(this);
        this.renderSuggestion = this.renderSuggestion.bind(this);
        this.createGame = this.createGame.bind(this);
    }

    componentDidMount() {
    }

    inputChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    }

    getSuggestions(value) {
        const inputValue = value.trim().toLowerCase();
        const inputLength = inputValue.length;
        // const { suggestions } = this.state;
        
        return inputLength === 0 ? [] : Object.keys(GAME_INFO).filter(game =>
          game.toLowerCase().slice(0, inputLength) === inputValue
        );
    }
    
    getSuggestionValue(suggestion) {
        return suggestion;
    }


    renderSuggestion(suggestion) {
        return (
            <div>
                {suggestion}
            </div>
        );
    }

    onChange(event, { newValue }) {
        this.setState({ value: newValue });
    }

    createGame() {
        const { value, passwordValue } = this.state;
        console.log(`JoinGamePage/onSubmit - Value: ${value} | GameInfo: ${GAME_INFO[value]}`);
        this.props.firebase.games()
          .add({
            password: passwordValue,
            ...GAME_INFO[value].initial
          })
          .then(docRef => {
              this.props.history.push(ROUTES.GAMES+"/"+String(docRef.id));
          });
    }

    onSuggestionsFetchRequested({ value }) {
        this.setState({ suggestions: this.getSuggestions(value) });
    }

    onSuggestionsClearRequested() {
        this.setState({ suggestions: [] });
    }

    render() {
        const { value, passwordValue, suggestions } = this.state;

        // Autosuggest will pass through all these props to the input.
        const inputProps = {
            placeholder: 'Search for a game...',
            value,
            onChange: this.onChange
        };

        const isInvalid = value === '' || passwordValue === '';

        return (
            <div>
                <h1>Join A Game</h1>
                Not yet implemented
                <h1>Create A Game</h1>
                <hr/>
                <AuthUserContext.Consumer>
                    { auth => {
                        if (auth) {
                            return (
                                <div>
                                    <Autosuggest
                                      suggestions={suggestions}
                                      onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                                      onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                                      getSuggestionValue={this.getSuggestionValue}
                                      renderSuggestion={this.renderSuggestion}
                                      inputProps={inputProps}
                                      focusInputOnSuggestionClick={false}
                                    />
                                    <input
                                      name={"passwordValue"}
                                      value={passwordValue}
                                      onChange={this.inputChange}
                                      type="text"
                                      placeholder="password"
                                    />
                                    <button disabled={isInvalid} onClick={this.createGame}>Create</button>
                                </div>
                            );
                        } else {
                            return (<div><Link to={ROUTES.SIGN_IN}>Sign in</Link> to create</div>);
                        }
                    }}
                </AuthUserContext.Consumer>
            </div>
        );
    } 
}

export default withFirebase(JoinGamePage); 
