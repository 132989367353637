import React, { Component } from 'react';
import {
  BrowserRouter as Router,
  Route,
} from 'react-router-dom';
// import { withFirebase } from '../Firebase'
import { withAuthentication } from '../Session'
 
import Navigation from '../Navigation';
import LandingPage from '../Landing';
import ProjectsPage from '../Projects';
import HomePage from '../Home';
import AccountPage from '../Account';
import SignInPage from '../SignIn'
import TicTacToePage from '../Game/Games/TicTacToe';
import FullPostPage from '../Post';
import EditPostPage from '../EditPost';
import TagHomePage, {TagPage} from '../Tag';
import EditTagPage from '../EditTag';
import { GamePage, JoinGamePage } from '../Game';
 
import * as ROUTES from '../../constants/routes';
import '../../css/index.css'
 
class App extends Component {
  constructor(props) {
    super(props);
 
    this.state = {
      authUser: null,
    };
  }

  componentDidMount() {
    this.listener = this.props.firebase.auth.onAuthStateChanged(authUser => {
      authUser
        ? this.setState({ authUser })
        : this.setState({ authUser: null });
    });
  }

  componentWillUnmount() {
    this.listener();
  }

  render() {
    return (
      <Router>
        <div>
          <Route path={[ROUTES.HOME,ROUTES.TAG_PAGE,ROUTES.EDIT_TAG,ROUTES.NEW_TAG,ROUTES.ACCOUNT,ROUTES.SIGN_IN,ROUTES.NEW_POST,ROUTES.FULL_POST,ROUTES.EDIT_POST,ROUTES.TIC_TAC_TOE]} component={Navigation} />
          <Route exact path={ROUTES.LANDING} component={LandingPage} />

          <Route exact path={ROUTES.PROJECTS} component={ProjectsPage} />

          <Route path={ROUTES.HOME} component={HomePage} />
          <Route path={ROUTES.ACCOUNT} component={AccountPage} />
          <Route path={ROUTES.SIGN_IN} component={SignInPage} />
          <Route path={ROUTES.FULL_POST+"/:pid"} component={FullPostPage} />
          <Route path={ROUTES.EDIT_POST+"/:pid"} component={EditPostPage} />
          <Route exact path={ROUTES.NEW_POST+"/:pid"} render={(props) => (<EditPostPage {...props} newPost={true} />)} />
          <Route exact path={ROUTES.NEW_POST} render={(props) => (<EditPostPage {...props} newPost={true} />)} />
          <Route exact path={ROUTES.TAG_PAGE} component={TagHomePage} />
          <Route exact path={ROUTES.TAG_PAGE+"/:tid"} component={TagPage} />
          <Route exact path={ROUTES.EDIT_TAG+"/:tid"} component={EditTagPage} />
          <Route exact path={ROUTES.NEW_TAG} render={(props) => (<EditTagPage {...props} newTag={true} />)} />

          <Route exact path={ROUTES.GAMES} component={JoinGamePage} />
          <Route exact path={ROUTES.GAMES+"/:gid"} component={GamePage} />
          <Route path={ROUTES.TIC_TAC_TOE} component={TicTacToePage} />
        </div>
      </Router>
    );
  }
}

export default withAuthentication(App);
