import React from 'react';
import { Link } from 'react-router-dom';
import { SignInPopUp } from '../SignIn';
import SignOutButton from '../SignOut';
//import { withFirebase } from '../Firebase';
import { AuthUserContext } from '../Session';
 
import * as ROUTES from '../../constants/routes';
 
const Navigation = ({ authUser }) => (
  <div>
    <div className="navigation">
      <AuthUserContext.Consumer>
        {authUser => authUser ? <SignOutButton /> : <SignInPopUp /> }
      </AuthUserContext.Consumer>
      <AuthUserContext.Consumer>
        {authUser => authUser && <Link className="nav_button" to={ROUTES.ACCOUNT}>Account</Link>}
      </AuthUserContext.Consumer>
      <Link className="nav_button" to={ROUTES.TIC_TAC_TOE}>TicTacToe</Link>
      <Link className="nav_button" to={ROUTES.TAG_PAGE}>Skills and Interests</Link>
      <Link className="nav_button" to={ROUTES.HOME}>Home</Link>
      <Link className="landing_button" to={ROUTES.LANDING}>Landing</Link>
    </div>
    <hr/>
    <hr/>
    <hr/>
    <hr/>
  </div>
);
 
export default Navigation;
