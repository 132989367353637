import React from 'react';

const game = () => { 
    return <div>Game 2 DIV</div>
};


export const GameInfo = {
    name: "G2",
    initial: {
        g2: "g2",
        type: "G2",
        players:[],
    }
}

export default game;
