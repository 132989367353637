import React, { Component } from 'react';

import ReactMarkdown from 'react-markdown';
import Popup from "reactjs-popup";
import Autosuggest from 'react-autosuggest';

import { Link } from 'react-router-dom';
 
import { withFirebase } from '../Firebase';
import { AuthUserContext } from '../Session';

import * as ROUTES from '../../constants/routes';

import '../../css/index.css';
import './tag.css';

class TagHomePage extends Component {
    constructor(props) {
        super(props);

        this.state = {
          tags: [],
          loading: false,
        };
    }

    componentDidMount() {
        this.setState({ loading: true });

        this.unsubscribe = this.props.firebase.tags()
          .onSnapshot(snapshot => {
            let tags = [];
            
            snapshot.forEach(doc => 
              tags.push({ ...doc.data(), tid: doc.id }),
            );

            this.setState({
              tags,
              loading: false,
            });
          });
    }

    componentWillUnmount() {
        this.unsubscribe();
    }

    displayTag(tag) {
        return (
            <Link key={tag.tid} to={ROUTES.TAG_PAGE+`/${tag.tid}`} className="big_pill" style={{background: tag.color[0], color: tag.color[1]}} >
                {tag.name + (tag.logs.length > 0 ? " / "+String(tag.logs[0].value)+" "+tag.logs[0].name : "")}
            </Link>
        );
    }

    render() {
        const { tags, loading } = this.state;
  
        if (typeof tags === "undefined" || tags === null || loading) {
            return <div>{loading && <div>Loading ...</div>}</div>
        } else {
            return (
                <div>
                    <Link to={ROUTES.NEW_TAG} className="new_tag_big_pill" >+</Link>
                    {tags.map(tag => {return this.displayTag(tag)})}
                </div>
            );
        }
    }
}



class TagPageBase extends Component {
    constructor(props) {
        super(props);
   
        this.tid = props.match.params.tid;
        this.state = {
          tag: null,
          loading: false,
        };

        this.sideMenu = this.sideMenu.bind(this);
        this.deleteTag = this.deleteTag.bind(this);
        this.displayLogs = this.displayLogs.bind(this);
        this.addEventPopup = this.addEventPopup.bind(this);
        this.removeLastEvent = this.removeLastEvent.bind(this);
        this.displayHeader = this.displayHeader.bind(this);
    }
   
    componentDidMount() {
        if (this.state.originalID !== null) {
          this.setState({ loading: true });
  
          // Get Follow Up info
          this.props.firebase.tag(this.tid).get()
            .then(doc => {
                this.setState({
                    tag: doc.data(),
                    loading: false,
                });
            });
  
        }
    }

    displayHeader() {
        const { tag } = this.state;
        return (
          <div className="tag_page_header"
               style={{background:tag.color[1],
                       color:tag.color[0],
                       borderTop:"2px solid "+tag.color[0],
                       borderBottom:"2px solid "+tag.color[0]
                     }} >
            {tag.name}
            {this.sideMenu()}
          </div>
        );
    }
  
    displayBody() {
        const { tag } = this.state;
        return (
            <div className="full_post" >
              {tag.body.map((element,index) => {
                if (element.type === "img") {
                  return <img key={index} src={element.content} alt="img" style={{boxSizing: "border-box", padding: "0px 20%", width: "100%", height:"auto"}} />;
                } else if (element.type === "text") {
                  return <ReactMarkdown key={index} className="post_body" source={element.content} />;
                } else {
                  return <p>Unknown type of content</p>;
                }
              })}
            </div>
        );
    }

    sideMenu() {
        return (
            <Popup trigger={<button style={{float: 'right', padding: '3px 0px'}}>{"\u22EE"}</button>} position="left top" closeOnEscape>
             { close => (
                 <div>
                   <Link className="post_link" to={ROUTES.EDIT_TAG+`/${this.tid}`}>Edit Page</Link>
                   <hr/>
                   <AuthUserContext.Consumer>
                       {authUser => authUser? this.deleteButton() : <Link to={ROUTES.SIGN_IN}>Delete Interest</Link>}
                   </AuthUserContext.Consumer>
                 </div>
              )}
            </Popup>
        );
    }

    deleteButton() {
        const { tag } = this.state;
         return (
           <Popup trigger={<p className="post_link">Delete Interest</p>} modal closeOnEscape>
            { close => (
                <div>
                  <h3>Are you sure you want to delete {tag.name}?</h3>
                  <hr/>
                  <button onClick={() => {this.deleteTag(); close()}} >Yes</button>
                  <button onClick={close} >Cancel</button>
                </div>
             )}
           </Popup>
         );
    }

    deleteTag = () => {
        console.log(`DELETE TAG CALLED ID: ${this.tid}`);
    }

    displayLogs() {
        const { tag } = this.state;
        return (
            <div>
                {tag.logs.map((log, index) => { return (
                    <div key={index} className="big_pill" style={{background:tag.color[0], color:tag.color[1]}} >
                        <AuthUserContext.Consumer>
                          { authUser => authUser?
                            this.removeLastEvent() :
                            <Link to={ROUTES.SIGN_IN} className="tag_button_small_pill" style={{color:tag.color[1], margin: "0px 5px 0px 0px"}} >&minus;</Link>
                          }
                        </AuthUserContext.Consumer>
                        {log.value+" "+log.name}
                        <AuthUserContext.Consumer>
                          { authUser => authUser?
                            this.addEventPopup(log) :
                            <Link to={ROUTES.SIGN_IN} className="tag_button_small_pill" style={{color:tag.color[1], margin: "0px 0px 0px 5px"}} >+</Link>
                          }
                        </AuthUserContext.Consumer>
                    </div>
                )})}
            </div>
        );
    }

    addEventPopup(log) {
        return (
            <Popup trigger={<div className="tag_button_small_pill" style={{margin: "0px 0px 0px 5px"}} >+</div>} position="right top" closeOnEscape>
              { close => (
                <div>
                  Add Event Popup
                </div>
              )}
            </Popup >
        );
    }

    removeLastEvent() {
        console.log(`DecEvent`);
        // <div className="tag_button_small_pill" style={{margin: "0px 5px 0px 0px"}} >&minus;</div> :
    }

    render() {
        const { tag, loading } = this.state;
  
        if (typeof tag === "undefined" || tag === null || loading) {
          return <div>{loading && <div>Loading ...</div>}</div>
        } else {
          return (
              <div>
                  {this.displayHeader()}
                  <div className="tag_page_description" >{tag.description}</div>
                  <hr/>
                  {this.displayLogs()}
                  <hr/>
                  {this.displayBody()}
              </div>
          );
        }
    } 
}



class TagBase extends Component {
    constructor(props) {
        super(props);
  
        this.tid = props.tid;
        this.remove = props.remove;
        this.linked = props.linked;
        this.state = {
            color: ['#000000','#FFFFFF'],
            displayName: null,
            loading: false,
        };
    }

    componentDidMount() {
        this.setState({loading: true});
        this.props.firebase.tag(this.tid).get()
          .then(doc => {
            const tag = doc.data();
            this.setState({
              color: tag.color,
              displayName: tag.displayName,
              loading: false,
            });
          })
    }
    
    render() {
        const { color, displayName } = this.state;
        if (this.linked) {
            return (
              <Link to={ROUTES.TAG_PAGE+`/${this.tid}`} className="small_pill" style={{background:color[0], color:color[1]}} >
                  {displayName}
                  {this.remove && <button className="tag_button_small_pill" style={{color:color[1]}} onClick={() => this.remove(this.tid)} >X</button>}
              </Link>
            );
        } else {
            return (
              <div className="small_pill" style={{background:color[0], color:color[1]}} >
                  {displayName}
                  {this.remove && <button className="tag_button_small_pill" style={{color:color[1]}} onClick={() => this.remove(this.tid)} >X</button>}
              </div>
            );
        }
    }
}
TagBase.defaultProps = {
    linked: true,
}


class AddTagButtonBase extends Component {
    constructor(props) {
        super(props);
        this.add = props.add;
        this.remove = props.remove;
        this.state = {
            tags: [],
            existingTags: props.tids,
            value: '',
            suggestions: [],
            loading: false,
        };

        this.onChange = this.onChange.bind(this);
        this.onSuggestionsFetchRequested = this.onSuggestionsFetchRequested.bind(this);
        this.onSuggestionsClearRequested = this.onSuggestionsClearRequested.bind(this);
        this.renderSuggestion = this.renderSuggestion.bind(this);
        this.onSuggestionSelected = this.onSuggestionSelected.bind(this);

    }

    componentDidMount() {
        this.setState({ loading: true });

        this.unsubscribe = this.props.firebase.tags()
          .onSnapshot(snapshot => {
            let tags = [];
            
            snapshot.forEach(doc => 
              tags.push({ ...doc.data(), tid: doc.id }),
            );

            this.setState({
              tags,
              loading: false,
            });
          });
    }

    getSuggestions(value) {
        const inputValue = value.trim().toLowerCase();
        const inputLength = inputValue.length;
        const { tags } = this.state;
    
        return inputLength === 0 ? [] : tags.filter(tag =>
          tag.name.toLowerCase().slice(0, inputLength) === inputValue
        );
    }
    
    getSuggestionValue(suggestion) {
        return suggestion.name;
    }
    
    // Use your imagination to render suggestions.
    renderSuggestion(suggestion) {
        const { existingTags } = this.state;
        return (
            <div>
                <div className="small_pill" style={{background:suggestion.color[0], color:suggestion.color[1]}}>{suggestion.name}</div>
                {existingTags.includes(suggestion.tid) && "\u2713"}
            </div>
        );
    }

    onChange(event, { newValue }) {
        this.setState({ value: newValue });
    }

    onSuggestionsFetchRequested({ value }) {
        this.setState({ suggestions: this.getSuggestions(value) });
    }

    onSuggestionsClearRequested() {
        this.setState({ suggestions: [] });
    }

    onSuggestionSelected(event, {suggestion}) {
        const { existingTags } = this.state;
        if (!existingTags.includes(suggestion.tid)) {
            this.add(suggestion.tid);
        } else {
            this.remove(suggestion.tid);
        }
    }

    render() {

        const { value, suggestions } = this.state;

        // Autosuggest will pass through all these props to the input.
        const inputProps = {
            placeholder: 'Search for a Tag...',
            value,
            onChange: this.onChange
        };

        return (
            <Popup trigger={<div className="new_tag_small_pill">+</div>} position="right top" closeOnEscape>
                <div>
                    <div>Add a Tag:</div>
                    <hr/>
                    <Autosuggest
                      suggestions={suggestions}
                      onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                      onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                      getSuggestionValue={this.getSuggestionValue}
                      renderSuggestion={this.renderSuggestion}
                      inputProps={inputProps}
                      onSuggestionSelected={this.onSuggestionSelected}
                      focusInputOnSuggestionClick={false}
                      alwaysRenderSuggestions={true}
                    />
                </div>
            </Popup>
        );
    }
}

class AddEventButtonBase extends Component {
    constructor(props) {
        super(props);
        this.add = props.add;
        this.remove = props.remove;
        this.state = {
            tags: [],
            existingTags: props.tids,
            value: '',
            suggestions: [],
            loading: false,
        };

        this.onChange = this.onChange.bind(this);
        this.onSuggestionsFetchRequested = this.onSuggestionsFetchRequested.bind(this);
        this.onSuggestionsClearRequested = this.onSuggestionsClearRequested.bind(this);
        this.renderSuggestion = this.renderSuggestion.bind(this);
        this.onSuggestionSelected = this.onSuggestionSelected.bind(this);

    }

    componentDidMount() {
        this.setState({ loading: true });

        this.unsubscribe = this.props.firebase.tags()
          .onSnapshot(snapshot => {
            let tags = [];
            
            snapshot.forEach(doc => 
              tags.push({ ...doc.data(), tid: doc.id }),
            );

            this.setState({
              tags,
              loading: false,
            });
          });
    }

    getSuggestions(value) {
        const inputValue = value.trim().toLowerCase();
        const inputLength = inputValue.length;
        const { tags } = this.state;
    
        return inputLength === 0 ? [] : tags.filter(tag =>
          tag.name.toLowerCase().slice(0, inputLength) === inputValue
        );
    }
    
    getSuggestionValue(suggestion) {
        return suggestion.name;
    }
    
    // Use your imagination to render suggestions.
    renderSuggestion(suggestion) {
        const { existingTags } = this.state;
        return (
            <div>
                <div className="small_pill" style={{background:suggestion.color[0], color:suggestion.color[1]}}>{suggestion.name}</div>
                {existingTags.includes(suggestion.tid) && "\u2713"}
            </div>
        );
    }

    onChange(event, { newValue }) {
        this.setState({ value: newValue });
    }

    onSuggestionsFetchRequested({ value }) {
        this.setState({ suggestions: this.getSuggestions(value) });
    }

    onSuggestionsClearRequested() {
        this.setState({ suggestions: [] });
    }

    onSuggestionSelected(event, {suggestion}) {
        const { existingTags } = this.state;
        if (!existingTags.includes(suggestion.tid)) {
            this.add(suggestion.tid);
        } else {
            this.remove(suggestion.tid);
        }
    }

    render() {
        // const { value, suggestions } = this.state;

        // Autosuggest will pass through all these props to the input.
        // const inputProps = {
        //     placeholder: 'Search for a Tag...',
        //     value,
        //     onChange: this.onChange
        // };

        // return (
        //     <Popup trigger={<div className="new_tag_small_pill">+</div>} position="right top" closeOnEscape>
        //         <div>
        //             <div>Add a Tag:</div>
        //             <hr/>
        //             <Autosuggest
        //               suggestions={suggestions}
        //               onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        //               onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        //               getSuggestionValue={this.getSuggestionValue}
        //               renderSuggestion={this.renderSuggestion}
        //               inputProps={inputProps}
        //               onSuggestionSelected={this.onSuggestionSelected}
        //               focusInputOnSuggestionClick={false}
        //               alwaysRenderSuggestions={true}
        //             />
        //         </div>
        //     </Popup>
        // );
        return <div>ADD EVENT TAG - More to come soon</div>
    }
}

export const AddTagButton = withFirebase(AddTagButtonBase);
export const AddEventButton = withFirebase(AddEventButtonBase);
export const Tag = withFirebase(TagBase);
export const DisplayTags = ({tids, remove, linked}) => {
    if (remove) {
        return <div>{tids.map((tid, index) => {return <Tag key={tid} tid={tid} remove={remove} linked={linked} />})}</div>
    } else {
        return <div>{tids.map((tid, index) => {return <Tag key={tid} tid={tid} linked={linked} />})}</div>
    }
};
export const TagPage = withFirebase(TagPageBase);
export default withFirebase(TagHomePage);
