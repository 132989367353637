import G1Page from './G1';
import G2Page from './G2';
import G3Page from './G3';
import G4Page from './G4';
import CockroachPokerPage from './CockroachPoker';
import DefaultPage from './Default';

export const G1=G1Page;
export const G2=G2Page;
export const G3=G3Page;
export const G4=G4Page;
export const CockroachPoker=CockroachPokerPage;
export const Default=DefaultPage;
