import React from 'react';

const game = () => { 
    return <div>Game 3 DIV</div>
};


export const GameInfo = {
    name: "G3",
    initial: {
        g3: "g3",
        type: "G3",
        players:[],
    }
}

export default game;
