import React, { Component } from 'react';
 
import Popup from "reactjs-popup";
// import Autosuggest from 'react-autosuggest';

// import { Link } from 'react-router-dom';

import { withFirebase } from '../../../Firebase';
// import { basicCondition, withAuthorization } from '../../../Session';
// import { AuthUserContext } from '../../../Session';

// import * as ROUTES from '../../../../constants/routes';

import '../../../../css/index.css';
import './index.css';

import * as IMAGES from './CardImages/images';

const CARDS = [
    { name:"bat",
      img:`url(${IMAGES.Bat})`,
    },
    { name:"fly",
      img:`url(${IMAGES.Fly}`,
    },
    { name:"cockroach",
      img:`url(${IMAGES.Cockroach}`,
    },
    { name:"toad",
      img:`url(${IMAGES.Toad}`,
    },
    { name:"rat",
      img:`url(${IMAGES.Rat}`,
    },
    { name:"scorpion",
      img:`url(${IMAGES.Scorpion}`,
    },
    { name:"spider",
      img:`url(${IMAGES.Spider}`,
    },
    { name:"stink bug",
      img:`url(${IMAGES.StinkBug}`,
    },
];


class CockroachPokerPage extends Component {
    constructor(props) {
        super(props);
        
        this.gid = props.gid ? props.gid : '';
        this.playid = props.playid ? props.playid : '';
        this.username = props.username ? props.username : '';
        this.state = {
            myTurn: false,
            count: [0,0,0,0,0,0,0,0],
            hand: [0,0,0,0,0,0,0,0],
            game: {
              isOver: true,
              comment: 'press reset to start game',
              player_counts: [],
              player_hands: [],
              inPlay: {},
            },
            claimValue: 0,
        };

        this.leaveGame = props.leaveGame;
        this.update = true;

        ////SAMPLE player_count element
        // {  playid: "fdskjklagjkl;adgjkl",
        //    username: "user",
        //    count: [0,0,0,0,0,0,0,0],
        // }

        ////SAMPLE player_count element
        // {  playid: "fdskjklagjkl;adgjkl",
        //    hand: [0,0,0,0,0,0,0,0],
        // }

        ////SAMPLE inPlay
        // inPlay:{
        //     cid: 0,
        //     claim: 0,
        //     invalid: [],
        //     to: '',
        //     from: '',
        // }

        this.reset = this.reset.bind(this);
        this.leave = this.leave.bind(this);
        this.sendCard = this.sendCard.bind(this);
        this.makeChoice = this.makeChoice.bind(this);
        this.gameOver = this.gameOver.bind(this);
    }

    componentDidMount() {
        this.setState({ loading: true });

        this.unsubscribe = this.props.firebase.game(this.gid)
          .onSnapshot(snapshot => {
            const doc = snapshot.data();
            const count = findObjectByID(doc.player_counts, this.playid);
            const hand = findObjectByID(doc.player_hands, this.playid);
            

            this.setState({
                myTurn: (Boolean(doc.inPlay) && doc.inPlay.from === '' && doc.inPlay.to === this.playid),
                count: count?count.count:[],
                hand: hand?hand.hand:[],
                game: {
                  isOver: doc.isOver,
                  comment: doc.comment,
                  player_counts: doc.player_counts,
                  inPlay: doc.inPlay,
                }
            });

            if (!count && this.update) {
                const tempCount = {playid:this.playid, username:this.username, count: [0,0,0,0,0,0,0,0]};
                console.log(`CockroachPoker/componentDidMount - Player ID: ${this.playid} | Count: ${count} | tempCount:`);
                console.log(tempCount);
                this.state.game.player_counts.push(tempCount);
                this.props.firebase.game(this.gid)
                  .update({
                    player_counts: this.state.game.player_counts,
                  })
                  .then(() => {
                    this.setState({ count: tempCount, loading: false });
                  });
            } else {
                this.setState({ loading: false });
            }
        });
    }

    componentWillUnmount() {
        this.unsubscribe();
        // Set player to inactive
    }

    // GAME FUNCTIONS
    reset() {
        // Check Actives?
        const { game } = this.state;

        let tempHands = [];
        let tempCounts = [];
        let deck = [0,0,0,0,0,0,0,0,
                    1,1,1,1,1,1,1,1,
                    2,2,2,2,2,2,2,2,
                    3,3,3,3,3,3,3,3,
                    4,4,4,4,4,4,4,4,
                    5,5,5,5,5,5,5,5,
                    6,6,6,6,6,6,6,6,
                    7,7,7,7,7,7,7,7];

        let handSize = Math.floor(deck.length/game.player_counts.length);

        const getHand = (num) => {
            let cards = [0,0,0,0,0,0,0,0];
            const dealSize = (deck.length % handSize) ? handSize+1 : handSize;
            for (let i=0; i<dealSize; i++) {
                cards[deck.splice(Math.floor(Math.random() * deck.length), 1)[0]] += 1;
            }
            return cards;
        };

        for (let i = 0; i < game.player_counts.length; i++) {
            tempCounts.push({playid: game.player_counts[i].playid, username: game.player_counts[i].username, count: [0,0,0,0,0,0,0,0]});
            tempHands.push({playid: game.player_counts[i].playid, hand: getHand(handSize)});
        }        
        this.props.firebase.game(this.gid)
          .update({
            player_hands: tempHands,
            player_counts: tempCounts,
            isOver: false,
            comment: "",
            inPlay: {
                cid: -1,
                claim: -1,
                invalid: [],
                to: tempCounts[0].playid,
                from: '',
            },
          });
    }
    
    gameOver(name) {
        this.props.firebase.game(this.gid)
          .update({
            isOver: true,
            comment: `${name} Lost!`,
          });
    }

    sendCard(toID, cid, claim) {
        // const { hand, game } = this.state;
        const { game } = this.state;
        let tempInPlay = {};

        // Set inPlay
        // Decrement hand cardID and push
        if (cid !== -1 && claim !== -1){
            tempInPlay = {
                cid: cid,
                claim: claim,
                invalid: [],
                to: toID,
                from: this.playid,
            }
        } else {
            let tempInvalid = [...game.inPlay.invalid];
            tempInvalid.push(game.inPlay.from);
            tempInPlay = {
                cid: game.inPlay.cid,
                claim: game.inPlay.claim,
                invalid: tempInvalid,
                to: toID,
                from: this.playid,
            }
        }
        
        this.props.firebase.game(this.gid).get()
          .then(doc => {
            let tempHands = doc.data().player_hands;
            tempHands[indexOfObjectByID(tempHands, this.playid)].hand[cid] -= 1;
            this.props.firebase.game(this.gid)
              .update({
                player_hands: tempHands,
                inPlay: tempInPlay,
              });
          });
    }

    makeChoice(choice) {
        const { game } = this.state;

        console.log("Before:");
        console.log(game);
        if ((choice && game.inPlay.cid === game.inPlay.claim) || (!choice && game.inPlay.cid !== game.inPlay.claim)){
            game.player_counts[indexOfObjectByID(game.player_counts, game.inPlay.from)].count[game.inPlay.cid] += 1;
            game.gameOver = game.player_counts[indexOfObjectByID(game.player_counts, game.inPlay.from)].count[game.inPlay.cid] > 3 ? game.player_counts[indexOfObjectByID(game.player_counts, game.inPlay.from)].name : "" ;
            game.inPlay = {
                cid: -1,
                claim: -1,
                invalid: [],
                to: game.inPlay.from,
                from: '',
            };
        } else {
            game.player_counts[indexOfObjectByID(game.player_counts, game.inPlay.to)].count[game.inPlay.cid] += 1;
            game.gameOver = game.player_counts[indexOfObjectByID(game.player_counts, game.inPlay.to)].count[game.inPlay.cid] > 3 ? this.username : "" ;
            game.inPlay = {
                cid: -1,
                claim: -1,
                invalid: [],
                to: game.inPlay.to,
                from: '',
            };
        }
        console.log("After:");
        console.log(game);
        this.props.firebase.game(this.gid)
          .update({
            player_counts: game.player_counts,
            inPlay: game.inPlay,
          })
          .then(() => {
            if (game.gameOver) {
                this.gameOver(game.gameOver);
            }
          })
    }

    leave() {
        this.update = false;
        this.leaveGame();
        const { game } = this.state;
        game.player_counts.splice(indexOfObjectByID(game.player_counts, this.playid), 1); 
        this.props.firebase.game(this.gid).update({ player_counts: game.player_counts });
    }

    // RENDER FUNCTIONS
    displayPlayers() {
        const { game } = this.state;

        return (
            <div style={{border:"1px solid black"}}>
                Players:
                {game.player_counts.map(count => {
                    return (
                        <div key={count.playid} className="player_count_bar" >
                            {count.username} - 
                            {count.count.map((card, index) => {
                                return (
                                    <div key={index} style={{display: "inline-block"}}>
                                        &nbsp;{card}x 
                                        <div
                                         className="small_card" 
                                         key={index}
                                         style={{
                                           backgroundImage: CARDS[index].img,
                                         }}
                                        />
                                    </div>
                                );
                            })}
                            &nbsp;
                            {game.inPlay.to === count.playid && "\u25CE"}
                            {game.inPlay.from === count.playid && "\u25C9"}
                            {game.inPlay.invalid.includes(count.playid) && "\u25FA"}
                        </div>
                    );
                })}
            </div>
        );
    }

    displayHand() {
        const { hand, game, myTurn } = this.state;

        return (
            <div style={{border:"1px solid black"}} >
                My Hand
                <div>
                    {hand.map( (card, index) => {
                        return (
                            <Popup trigger={
                                        <div
                                         className="big_card"
                                         key={index}
                                         style={{
                                           backgroundImage: CARDS[index].img,
                                           opacity: card ? 1 : 0.5,
                                         }}
                                        >
                                            <div className="big_card_text" >{card}x</div>
                                        </div>
                                   }
                              key={"popup"+index}
                              modal
                              closeOnEscape
                              disabled={!myTurn || card === 0}
                            >
                                <div>
                                    Send Card to...
                                    <br/>
                                    Claim:
                                    <Popup
                                        trigger={<button>{CARDS[this.state.claimValue].name}</button>}
                                        position="right top"
                                    >
                                        <div>
                                            {CARDS.map((card, index) => { return (
                                                <button key={index} onClick={() => this.setState({ claimValue:index })}>{card.name}</button>
                                            )})}
                                        </div>
                                    </Popup>
                                    {game.player_counts.map(count => {
                                        if (count.playid !== this.playid && !game.inPlay.invalid.includes(this.playid)) {
                                            return (
                                                <div key={count.playid} >
                                                    <br/>
                                                    <div onClick={() => this.sendCard(count.playid, index, this.state.claimValue)}>
                                                        {count.username}
                                                    </div>
                                                </div>
                                            );
                                        }
                                        return (
                                            <div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </Popup>
                        );
                    })}
                </div>
            </div>
        );
    }
    
    displayChoice() {
        const { myTurn, game } = this.state;

        if (!myTurn && game.inPlay.to === this.playid) {
            return (
                <div>
                    {findObjectByID(game.player_counts, game.inPlay.from).username}
                    :&nbsp;{"this is a "}{CARDS[game.inPlay.claim].name}.
                    <button onClick={() => this.makeChoice(true)} >It is a {CARDS[game.inPlay.claim].name}</button>
                    <button onClick={() => this.makeChoice(false)} >It is not a {CARDS[game.inPlay.claim].name}</button>
                    <Popup trigger={<button>Send to...</button>} disabled={game.inPlay.invalid.length >= (game.player_counts.length - 2)} modal>
                        <div>
                            Send Card to...
                            {game.player_counts.map(count => {
                                if (count.playid !== this.playid && !game.inPlay.invalid.includes(this.playid)) {
                                    return (
                                        <div key={count.playid}>
                                            <br/>
                                            <div onClick={() => this.sendCard(count.playid, -1, -1)}>
                                                {count.username}
                                            </div>
                                        </div>
                                    );
                                }
                                return (
                                    <div>
                                    </div>
                                );
                            })}
                        </div>
                    </Popup>
                </div>
            );
        } else {
            return <div></div>
        }
    }

    // RENDER
    render() {
        return (
            <div>
                <h1>Cockroach Poker - {this.username}</h1>
                <button onClick={this.reset} >Reset</button>
                <button onClick={this.leave} >Leave</button>
                {this.state.game.comment}
                <hr/>
                {!this.state.game.isOver && this.displayPlayers()}
                {!this.state.game.isOver && this.displayHand()}
                {!this.state.game.isOver && this.displayChoice()}
            </div>
        );
    } 
}

const findObjectByID = (list, value) => {
    const newList = list.filter(obj => obj.playid === value);
    if (newList.length > 0) {
        return newList[0];
    } else {
        return false;
    }
};

const indexOfObjectByID = (list, value) => {
    for(var i = 0; i < list.length; i += 1) {
        if(list[i].playid === value) {
            return i;
        }
    }
    return -1;
};


export const GameInfo = {
    name: "Cockroach Poker",
    initial: {
        type: "CockroachPoker",
        isOver: true,
        comment: "",
        player_counts: [],
        player_hands: [],
        inPlay: {
            cid: -1,
            claim: -1,
            invalid: [],
            to: '',
            from: '',
        },
    }
}

export default withFirebase(CockroachPokerPage); 
