import React, { Component } from 'react';

// import ReactMarkdown from 'react-markdown';
import Popup from "reactjs-popup";

// import { Link } from 'react-router-dom';
// import { withFirebase } from '../Firebase';
import { basicCondition, withAuthorization } from '../Session';

import { DisplayTags, AddTagButton, AddEventButton } from '../Tag';
 
import '../../css/index.css';

import * as ROUTES from '../../constants/routes';
 
class EditPostPage extends Component {
    constructor(props) {
        super(props);
        
        this.newPost = props.newPost;
        this.state = {
            title: '',
            body: [],
            followUp: ((typeof props.match.params === "undefined" || typeof props.match.params.pid === "undefined") ? [] :  props.match.params.pid ),
            time: null,
            tags: [],
            pid: (props.newPost? null : props.match.params.pid),
            error: null,
            loading: false,
            post: null,
            year: 0,
            day: 0,
            month: 0,
            hour: 0,
            minute: 0,
        };

        this.addTag = this.addTag.bind(this);
        this.removeTag = this.removeTag.bind(this);
    }
   
    componentDidMount() {
        if (this.state.pid !== null && !this.newPost) {
          this.setState({ loading: true });
  
          this.props.firebase
            .post(this.state.pid)
            .get()
            .then(doc => {
              let data = doc.data();
              this.setState({
                title: data.title,
                body: data.body,
                followUp: data.followUp,
                time: data.time,
                tags: data.tags,
                loading: false,
                post: data,
                year: data.time.toDate().getFullYear(),
                day: data.time.toDate().getDate(),
                month: data.time.toDate().getMonth()+1,
                hour: data.time.toDate().getHours(),
                minute: data.time.toDate().getMinutes(),
              });
            });
        }
    }
  
    onSubmit = event => {
        const { title, body, followUp, tags, year, day, month, hour, minute } = this.state;
  
        if (this.newPost) {
            this.props.firebase
              .posts()
              .add({
                title: title,
                body: body,
                followUp: followUp,
                time: this.props.firebase.Timestamp.now(),
                tags: tags,
              })
              .then(docRef => {
                console.log(`Document written with ID: ${docRef.id}`);
                if (typeof followUp === 'string') {
                    this.props.firebase
                      .post(followUp)
                      .get()
                      .then( doc => {
                        let tempFollow = doc.data().followUp;
                        tempFollow.push(docRef.id);
                        this.props.firebase
                          .post(followUp)
                          .update({
                            followUp:tempFollow,
                          });
                      });
                }
                if (this.props.history.length > 0 && !this.newPost){
                  this.props.history.push({pathname: ROUTES.FULL_POST+`/${docRef.id}`});
                } else {
                  this.props.history.push(ROUTES.HOME);
                }
              })
              .catch(error => {
                console.log(`Error: ${error}`)
                this.setState({ error });
              });
        } else {
            this.props.firebase
              .post(this.state.pid)
              .set({
                title: title,
                body: body,
                followUp: followUp,
                time: new this.props.firebase.Timestamp(new Date(year, Number(month)-1, day, Number(hour), minute).getTime()/1000, 0),
                tags: tags,
              })
              .then(() => {
                console.log(`Document written with ID: ${this.state.pid}`);
                if (this.props.history.length > 0 && !this.newPost){
                  this.props.history.push({pathname: ROUTES.FULL_POST+`/${this.state.pid}`});
                } else {
                  this.props.history.push(ROUTES.HOME);
                }
              })
              .catch(error => {
                console.log(`Error: ${error}`)
                this.setState({ error });
              });
        }
  
        event.preventDefault();
    }
  
    onCancel = event => {
        if (this.props.history.length > 0 && !this.newPost){
          this.props.history.push({pathname: ROUTES.FULL_POST+`/${this.state.pid}`});
        } else {
          this.props.history.push(ROUTES.HOME);
        }
    }
  
    addElement = type => {
        let body = [...this.state.body];
        body.push({type:type, content:""});
        this.setState({ body: body });
    }
    
    removeElement = index => {
        let body = [...this.state.body];
        body.splice(index, 1);
        this.setState({ body: body });
    }
   
    onChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    }
   
    changeBody = event => {
        let body = [...this.state.body];
        body[event.target.name].content = event.target.value;
        this.setState({ body: body });
    }
    
    timestampForm = () => {
        const {
            year,
            day,
            month,
            hour,
            minute,
        } = this.state;
        return (
            <div>
              <input
                name="year"
                value={year}
                onChange={this.onChange}
                type="number"
                placeholder="year"
              /> 
              <input
                name="day"
                value={day}
                onChange={this.onChange}
                type="number"
                placeholder="day"
              /> 
              <input
                name="month"
                value={month}
                onChange={this.onChange}
                type="number"
                placeholder="month"
              /> 
              <input
                name="hour"
                value={hour}
                onChange={this.onChange}
                type="number"
                placeholder="hour"
              /> 
              <input
                name="minute"
                value={minute}
                onChange={this.onChange}
                type="number"
                placeholder="minute"
              /> 
            </div>
        );
    }

    titleForm = () => {
        const {
            title,
        } = this.state;
        return (
            <textarea
              className="post_title"
              name="title"
              value={title}
              onChange={this.onChange}
              type="text"
              placeholder="Title"
            />
        );
    }

    addTag(tid) {
        const { tags } = this.state;

        if (!tags.includes(tid)) {
            tags.push(tid);
            this.setState({ tags });
            this.forceUpdate();
        }
    }
    
    removeTag(tid) {
        console.log(`Remove Tag: ${tid}`)

        const { tags } = this.state;
        if (tags.includes(tid)) {
            tags.splice(tags.indexOf(tid),1);
            this.setState({ tags });
        }
    }

    bodyForm = () => {
        const {
            body,
        } = this.state;
        return (body.map( (element, index) => { 
            if (element.type === 'text') {
              return (
                <div key={index}>
                  <textarea
                    className="post_body"
                    name={index}
                    value={element.content}
                    onChange={this.changeBody}
                    type="text"
                    placeholder="Write something here..."
                  />
                  <button type="button" onClick={() => this.removeElement(index)} >-</button>
                </div>
              );
            } else if (element.type === 'img') {
              return (
                <div key={index}>
                  <img src={element.content} alt="img" style={{boxSizing: "border-box", padding: "0px 20%", width: "100%", height:"auto"}} />
                  <input
                    key={index}
                    name={index}
                    value={element.content}
                    onChange={this.changeBody}
                    type="text"
                    placeholder="image url"
                  /> 
                  <button type="button" onClick={() => this.removeElement(index)} >-</button>
                </div>
              );
            } else { return <p>Invalid Type: {element.type}</p> }
        }));
    }

    addElementPopup = () => {
        return (
            <Popup trigger={<button type="button">+</button>} modal closeOnEscape >
              { close => (
                <div>
                  <button type="button" onClick={() => {this.addElement('text'); close()}} >Text</button>
                  <button type="button" onClick={() => {this.addElement('img'); close()}} >Image</button>
                  <button type="button" onClick={close} >Cancel</button>
                </div>
              )}
            </Popup>
        );
    }
  
    render() {
        const {
            title,
            body,
            tags,
            post,
            pid,
            error,
            loading,
            year,
            day,
            month,
            hour,
            minute,
        } = this.state;

        const isInvalidNew = title === '' || body.length < 1;
        const isInvalidOld =
          title === '' || body.length < 1 ||
          year < 0 || typeof year === 'undefined' ||
          day < 1 || day > 31 || typeof day === 'undefined' ||
          month < 1 || month > 12 || typeof month === 'undefined' ||
          hour < 0 || hour > 23 || typeof hour === 'undefined' ||
          minute < 0 || minute > 59 || typeof minute === 'undefined' ||
          Date.UTC(year, month-1, day, hour, minute) > this.props.firebase.Timestamp.now().toMillis();
        
   
        if ((typeof post === "undefined" || post === null || loading) && !this.newPost) {
          return (<div>{loading && <div>Loading ...</div>}</div>);
        } else {
          return (
            <form onSubmit={this.onSubmit}>
              {!this.newPost && this.timestampForm()}
              <button disabled={this.newPost? isInvalidNew : isInvalidOld} type="submit">Save Changes</button>
              <button onClick={this.onCancel}>Cancel</button>
              {this.titleForm()}
              <br/>
              <div>
                <DisplayTags tids={tags} remove={this.removeTag} linked={false} />
                <AddTagButton tids={tags} add={this.addTag} remove={this.removeTag} />
                <AddEventButton pid={pid} />
              </div>
              {this.bodyForm()}
              {this.addElementPopup()}
              {error && <p>{error.message}</p>}
            </form>
          );
        }
    }
}
EditPostPage.defaultProps = {
    newPost: false,
}
 
export default withAuthorization(basicCondition)(EditPostPage); 
