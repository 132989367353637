import React from 'react';
import './index.css';

import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";

class ExperienceAccordian extends React.Component{
    constructor(props){
        super(props);
        this.state = {
          expand:false
        }

        this.experience = {}
        if (this.props.experience){
            this.experience = this.props.experience
        }

        this.id =           this.experience.id;
        this.title =        this.experience.title;
        this.date =         this.experience.date;
        this.description =  this.experience.description;
    }

    render(){
        return (
          <Accordion square expanded={this.props.expanded} onChange={this.props.handleChange} style={{boxShadow:'none'}}>
            <AccordionSummary className='drop' aria-controls={"panel"+this.id+"d-content"} id={"panel"+this.id+"d-header"} style={{boxShadow:'none'}}>
              <div className='date'>{this.date}</div><div className='title'>{this.title}</div>
            </AccordionSummary>
            <AccordionDetails style={{boxShadow:'none'}}>
              <div className='description'>{this.description}</div>
            </AccordionDetails>
          </Accordion>
        );

    }
}

export default ExperienceAccordian;
