import React from 'react';
 
import { withFirebase } from '../Firebase';

import '../../css/index.css'
 
const SignOutButton = ({ firebase }) => (
  <p className="nav_button" onClick={firebase.doSignOut} >
    Sign Out
  </p>
);
 
export default withFirebase(SignOutButton);
