import React from 'react';
import './index.css';
import StackGrid from "react-stack-grid";

import { withFirebase } from '../Firebase';
// import { Link } from 'react-router-dom';
// import * as ROUTES from '../../constants/routes';

// import Grid from '@material-ui/core/Grid';
// import Button from '@material-ui/core/Button';

import NavButton from '../NavButton';
import PageSection from '../PageSection';
import ProjectCard from '../ProjectCard';

class ProjectsPage extends React.Component {
    constructor(props) {
      super(props);
 
      this.state = {
        loading: false,
      };
    }

    render() {

      return (
        <div className="App">

          {/*Nav Button*/}
          <NavButton/>

          {/*1.About Section*/}
          <PageSection color='#FFFFFF'>
          </PageSection>

          {/*2.Projects*/}

          <PageSection color='#F4F8F6'>
            <h3>Projects</h3>
            <AllProjects/>
          </PageSection>

        </div>
      );
    }
}

class ProjectBase extends React.Component {
    constructor(props) {
      super(props);
 
      this.state = {
        loading: false,
        projects: [],
      };
    }
 
    componentDidMount() {
      this.setState({ loading: true });

      this.unsubscribe = this.props.firebase
        .projects()
        .onSnapshot(snapshot => {
          let projects = [];
          
          snapshot.forEach(doc => 
            projects.push({ ...doc.data(), id: doc.id }),
          );

          // // TESTING  
          // let all_projects = {};
          // snapshot.forEach(doc => 
          //   all_projects[doc.id] = { ...doc.data(), id: doc.id },
          // );
          // console.log('All Projects:');
          // console.log(all_projects);

          // this.props.firebase
          //   .pageInfo('landing')
          //   .get()
          //   .then(doc => {
          //       let projects = [];

          //       let landing_project_ids = doc.data().landprojects
          //       console.log('LP IDs:');
          //       console.log(landing_project_ids);
          //       for (let lpid = 0; lpid < landing_project_ids.length; lpid++) {
          //           if (landing_project_ids[lpid] in all_projects) {
          //               projects.push(all_projects[landing_project_ids[lpid]])
          //           }
          //       }
          //       console.log('Projects:');
          //       console.log(projects);

          //       this.setState({
          //         projects,
          //         loading: false,
          //       });
          //   });
          // // TESTING END 
          console.log(projects);

           this.setState({
             projects,
             loading: false,
           });
      });
    }

    render() {
      const { projects } = this.state;

      return (
        <StackGrid
          columnWidth={400}
          gutterWidth={10}
          gutterHeight={10}
          style={{padding:'0px 0px 20px 0px'}}
        >
          {projects.map(project => (
            <ProjectCard key={project.id} project={project}/>
          ))}
        </StackGrid>
      );
    }
}
const AllProjects = withFirebase(ProjectBase);


export default ProjectsPage;
