import React, { Component, createRef } from 'react';

import ReactMarkdown from 'react-markdown';
import Popup from "reactjs-popup";
// REMOVE: import ImgSlider from "react-simple-image-slider";

import { Link } from 'react-router-dom';
 
import { withFirebase } from '../Firebase';
import { AuthUserContext } from '../Session';

import { DisplayTags } from '../Tag'; 


import * as ROUTES from '../../constants/routes';

import '../../css/index.css';

class FullPostPage extends Component {
    constructor(props) {
        super(props);
   
        this.state = {
          posts: [],
          pids: [props.match.params.pid],
          refs: [],
          originalID: props.match.params.pid,
          loading: false,
        };
        this.displayPosts = this.displayPosts.bind(this);
    }
   
    componentDidMount() {
        if (this.state.originalID !== null) {
          this.setState({ loading: true });
          let posts = [];
          let pids = [];
          let refs = [];
        
  
          // Get Follow Up info
          this.props.firebase
            .post(this.state.pids[0])
            .get()
            .then(doc => {
              posts.push(doc.data());
              refs.push(createRef());
  
              // Populating Lists
              // Getting Top Post
              if (typeof posts[0].followUp === "string"){
                // set post to topPost
                pids.push(posts[0].followUp);
                this.props.firebase
                  .post(posts[0].followUp)
                  .get()
                  .then(doc => {
                    posts[0] = doc.data();
                    // Populate ID List
                    pids.push(...posts[0].followUp)
                    // Populate Post List
                    posts[0].followUp.reduce( (chain,pid) => 
                      chain.then(() => new Promise(resolve => {
                        console.log(`Debug C: ${pid}`)
                        this.props.firebase
                          .post(pid)
                          .get()
                          .then(doc => {
                            posts.push(doc.data());
                            console.log(`Debug D: Completed ${pid} ${typeof doc.data()}`)
                            refs.push(createRef());
                            resolve();
                          })
                          .catch(error => console.log(`ERROR: ${error}`));
                        })),
                      Promise.resolve())
                      .then(() => {
                        console.log(`Debug E: Num Posts: ${posts.length} | First Type ${typeof posts[0]} | Last Type: ${typeof posts[posts.length-1]} `)
                        console.log(`Debug F: ${this.state.pids}`);
                        this.setState({
                          posts: posts,
                          pids: pids,
                          refs: refs,
                          loading: false,
                        });
                      });
                  });
  
              } else if (Array.isArray(posts[0].followUp)){
                console.log(`Debug A: ${pids}`)
                // Populate ID List
                pids.push(this.state.originalID);
                pids.push(...posts[0].followUp)
                console.log(`Debug B: Ids: ${pids} | Post Length: ${posts.length}`)
                // Populate Posts
                posts[0].followUp.reduce( (chain,pid) => 
                  chain.then(() => new Promise(resolve => {
                    console.log(`Debug C: ${pid}`)
                    this.props.firebase
                      .post(pid)
                      .get()
                      .then(doc => {
                        console.log(`Debug D: Completed ${pid}`)
                        posts.push(doc.data());
                        refs.push(createRef());
                        resolve();
                      })
                      .catch(error => console.log(`ERROR: ${error}`));
                    })),
                  Promise.resolve())
                  .then(() => {
                    console.log(`Debug E: Num Posts: ${posts.length} | Second Post Type: ${typeof posts[1]} | Type of Time: ${typeof posts.length}`)
                    this.setState({
                      posts: posts,
                      pids: pids,
                      refs: refs,
                      loading: false,
                    });
                    console.log(`Debug F: ${this.state.pids}`);
                  });
              } else {
                console.log(`No Follow Up Found: ${typeof posts[0].followUp}, not ${typeof []}`);
              }
            });
  
        }
    }
  
    componentDidUpdate() {
        const { pids, refs, originalID, loading} = this.state;
        if (!loading && refs[pids.indexOf(originalID)].current !== null) {
            console.log(refs);
            console.log(pids);
            console.log(pids.indexOf(originalID));
            console.log(refs[pids.indexOf(originalID)].current)
            window.scroll(0, refs[pids.indexOf(originalID)].current.offsetTop);
        }
    }


    displayPosts() {
        const { posts, pids, refs } = this.state;

        return (
          <div>
            {posts.map((post, index) => {return this.displayPost(post, pids[index], refs[index], pids[0], (index !== (pids.length - 1)))})}
          </div>
        );
    }

    displayPost(post, pid, pRef, topID, notLast) {
        console.log(`ID: ${pid}`);
        return (
          <div>
            <div className="full_post" ref={ref => pRef.current=ref}>
              <h1 className="post_title">{post.title}</h1>
              {this.sideMenu(post, pid)}
              <DisplayTags tids={post.tags}/>
              <h5>{post.time.toDate().toLocaleDateString("en-US")}</h5>
              
              {post.body.map((element,index) => {
                if (element.type === "img") {
                  return <img key={index} src={element.content} alt="img" style={{boxSizing: "border-box", padding: "0px 20%", width: "100%", height:"auto"}} />;
                } else if (element.type === "text") {
                  return <ReactMarkdown key={index} className="post_body" source={element.content} />;
                } else {
                  return <p>Unknown type of content</p>;
                }
              })}
            </div>
            {notLast && <div className="post_border"></div>}
          </div>
        );
    }

    sideMenu(post, pid) {
        return (
            <Popup trigger={<button style={{float: 'right', padding: '3px 0px'}}>{"\u22EE"}</button>} position="left top" closeOnEscape>
             { close => (
                 <div>
                   <Link className="post_link" to={ROUTES.EDIT_POST+`/${pid}`}>Edit Post</Link>
                   <hr/>
                   <Link className="post_link" to={ROUTES.NEW_POST+`/${Array.isArray(post.followUp) ? pid : post.followUp}`}>Continue Thread</Link>
                   <hr/>
                   <AuthUserContext.Consumer>
                       {authUser => authUser? this.deleteButton(post, pid) : <Link to={ROUTES.SIGN_IN}>Delete Post</Link>}
                   </AuthUserContext.Consumer>
                 </div>
              )}
            </Popup>
        );
    }

    deleteButton(post, pid) {
         return (
           <Popup trigger={<p className="post_link">Delete Post</p>} modal closeOnEscape>
            { close => (
                <div>
                  <h3>Are you sure you want to delete {post.title}?</h3>
                  <hr/>
                  <button onClick={() => {deletePost(pid, this.props.firebase); close()}} >Yes</button>
                  <button onClick={close} >Cancel</button>
                </div>
             )}
           </Popup>
         );
    }

    render() {
        const { posts, loading } = this.state;
  
        if (typeof posts === "undefined" || posts === null || loading) {
          return <div>{loading && <div>Loading ...</div>}</div>
        } else {
          return this.displayPosts();
        }
    } 
}

const deletePost = (id, firebase) => {
    console.log(`DELETE POST CALLED ID: ${id}`);
    firebase.post(id).get()
      .then(doc => {
        const post = doc.data();
        if (Array.isArray(post.followUp)) {
            if(post.followUp.length === 0) {
                console.log(`P1: Post is not part of a thread`);
                console.log(`Delete Post P1`);
                firebase.post(id).delete();
            } else {
                console.log(`P2: Post is the Head of a thread`);
                console.log(`Delete Post P2.A`);
                // 1. Get ID of next post for copy and deletion, and remove the top of the followUp array
                const idToDelete = post.followUp[0];
                const TempFollowUp = post.followUp.slice(1);
                console.log(`Delete Post P2.B: ${idToDelete} ${TempFollowUp}`);
                // 2. Update post with first followUp post
                firebase.post(idToDelete)
                  .get()
                  .then( doc => {
                    const delPost = doc.data();
                    console.log(`Delete Post P2.C: ${delPost.title}`);
                    firebase.post(id)
                      .set({
                        title: delPost.title,
                        body: delPost.body,
                        followUp: TempFollowUp,
                        time: delPost.time,
                        tags: delPost.tags,
                      })
                      .then( docRef => {
                        // 3. Delete former first followUp post 
                        console.log(`Delete Post P2.D:`);
                        firebase.post(idToDelete).delete();
                      })
                  });
            }
        } else {
            console.log(`P3: Post is a child post in a thread`);
            console.log(`Delete Post P3.A`);
            // 1. Get the Top Post
            firebase.post(post.followUp).get()
              .then( doc => {
                // 2. Remove the post id from the top post's followUp
                let TempFollowUp = doc.data().followUp;
                const delIdx = TempFollowUp.indexOf(id);
                TempFollowUp.splice(delIdx, 1);
            
                console.log(`Delete Post P3.B: ${TempFollowUp} ${delIdx}`);
                // 3. Update the top post's followUp
                firebase.post(post.followUp)
                  .update({
                    followUp: TempFollowUp,
                  })
                  .then( docRef => {
                    // 4. Delete the post
                    console.log(`Delete Post P3.C`);
                    firebase.post(id).delete();
                  })
              });
        }
      });
};



class SmallPostBase extends Component {
    constructor(props) {
        super(props);
  
        this.post = props.post;
        this.state = {
            parentTitle: "",
            loading: false,
        };

        this.sideMenu = this.sideMenu.bind(this);
    }

    componentDidMount() {
        if (typeof this.post.followUp === 'string'){
            this.setState({loading: true});
            this.props.firebase.post(this.post.followUp)
              .get()
              .then(doc => {
                this.setState({
                  parentTitle: doc.data().title,
                  loading: false
                });
              })
        }
    }
    
    deleteButton() {
         return (
           <Popup trigger={<p className="post_link">Delete Post</p>} modal closeOnEscape>
            { close => (
                <div>
                  <h3>Are you sure you want to delete {this.post.title}?</h3>
                  <hr/>
                  <button onClick={() => {deletePost(this.post.pid, this.props.firebase); close()}} >Yes</button>
                  <button onClick={close} >Cancel</button>
                </div>
             )}
           </Popup>
         );
    }

    sideMenu() {
        return (
            <Popup trigger={<button style={{float: 'right', padding: '3px 0px'}}>{"\u22EE"}</button>} position="left top" closeOnEscape>
             { close => (
                 <div>
                   <Link className="post_link" to={ROUTES.EDIT_POST+`/${this.post.pid}`}>Edit Post</Link>
                   <hr/>
                   <Link className="post_link" to={ROUTES.NEW_POST+`/${Array.isArray(this.post.followUp) ? this.post.pid : this.post.followUp}`}>Continue Thread</Link>
                   <hr/>
                   <AuthUserContext.Consumer>
                       {authUser => authUser? this.deleteButton() : <Link to={ROUTES.SIGN_IN}>Delete Post</Link>}
                   </AuthUserContext.Consumer>
                 </div>
              )}
            </Popup>
        );
    }
    
    render() {
        return (
          <div className="small_post">
            {this.sideMenu()}
            <Link className="post_link" to={ROUTES.FULL_POST+`/${this.post.pid}`} >
              {this.state.parentTitle !== "" && <h5 style={{color:'grey', padding:'0px', margin:'0px'}} >{this.state.parentTitle}</h5>}
              <h3 style={{padding:'0px', margin:'0px'}} >{this.post.title}</h3>
              <DisplayTags tids={this.post.tags}/>
              <h5 style={{color:'grey'}}>{this.post.time.toDate().toLocaleDateString("en-US")}</h5>
            </Link>
          </div>
        );
    }
}

export const SmallPost = withFirebase(SmallPostBase);
export default withFirebase(FullPostPage);
