export const LANDING = '/';
export const HOME = '/home';
export const ACCOUNT = '/account';
export const SIGN_IN = '/signin';

export const FULL_POST = '/full-post';
export const NEW_POST = '/new-post';
export const EDIT_POST = '/edit-post';

export const TAG_PAGE = '/skills-and-interests';
export const NEW_TAG = '/new-interests';
export const EDIT_TAG = '/edit-interest';

export const PROJECTS = '/projects';

export const GAMES = '/games'
export const TIC_TAC_TOE = '/games/tic-tac-toe';
