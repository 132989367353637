import React from 'react';

const game = () => { 
    return <div>Game 1 DIV</div>
};


export const GameInfo = {
    name: "G1",
    initial: {
        g1: "g1",
        type: "G1",
        players:[],
    }
}

export default game;
