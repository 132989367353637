import React from 'react';
import './index.css';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
//import Link from '@material-ui/core/Link';
import { Link } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import { Menu as MenuIcon } from '@material-ui/icons';
// import { SignInPopUp } from '../SignIn';
import SignOutButton from '../SignOut';
import { AuthUserContext } from '../Session';
 
// import * as ROUTES from '../../constants/routes';
//  
// const Navigation = ({ authUser }) => (
//   <div>
//     <div className="navigation">
//       <AuthUserContext.Consumer>
//         {authUser => authUser ? <SignOutButton /> : <SignInPopUp /> }
//       </AuthUserContext.Consumer>

// {authUser => authUser ? <SignOutButton /> : <SignInPopUp /> }
// {authUser => authUser ? <SignOutButton /> : <SignInPopUp /> }
// {<MenuItem onClick={handleClose}>Login - Coming Soon</MenuItem>}

import * as ROUTES from '../../constants/routes';

function NavButton(authUser) {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    return (
      <div className="landing-dropdown">
        <IconButton aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
          <MenuIcon />
        </IconButton>
        <Menu 
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}

          anchorReference="anchorPosition"
          anchorPosition={{ top: 40, left: 25 }}
        >
          <MenuItem onClick={handleClose}><Link class='nav_button_link' to={ROUTES.LANDING}>Home</Link></MenuItem>
          <MenuItem onClick={handleClose}><Link class='nav_button_link' to={ROUTES.PROJECTS}>Projects</Link></MenuItem>
          <MenuItem onClick={handleClose}>Blog - Coming Soon</MenuItem>
          <MenuItem onClick={handleClose}>
            <AuthUserContext.Consumer>
              {authUser => authUser ? <SignOutButton /> : <Link class='nav_button_link' to={ROUTES.SIGN_IN}>Sign In</Link>}
            </AuthUserContext.Consumer>
          </MenuItem>
        </Menu>
      </div>
    );
}

// <MenuItem onClick={handleClose}><Link href='/projects'>Projects</Link></MenuItem>

export default NavButton;
