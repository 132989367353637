import {GameInfo as CockroachPoker} from './CockroachPoker';
import {GameInfo as G1} from './G1';
import {GameInfo as G2} from './G2';
import {GameInfo as G3} from './G3';
import {GameInfo as G4} from './G4';

const GAME_INFO = {
    "Cockroach Poker":CockroachPoker,
    G1: G1,
    G2: G2,
    G3: G3,
    G4: G4,
};

export default GAME_INFO; 
