import React, { Component } from 'react';
import { Link } from 'react-router-dom';
 
import { withFirebase } from '../Firebase';
import { SmallPost } from '../Post';

import * as ROUTES from '../../constants/routes';

import '../../css/index.css';
import './home.css';
 
class HomePage extends Component {
  constructor(props) {
    super(props);
 
    this.state = {
      loading: false,
      posts: [],
    };
  }
 
  componentDidMount() {
    this.setState({ loading: true });

    this.unsubscribe = this.props.firebase
      .posts()
      .orderBy('time', 'desc')
      .onSnapshot(snapshot => {
        let posts = [];
        
        snapshot.forEach(doc => 
          posts.push({ ...doc.data(), pid: doc.id }),
        );

        this.setState({
          posts,
          loading: false,
        });
    });
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  render() {
    const { posts, loading } = this.state;
 
    return (
      <div className="home_page">
        <Link className="post_link" to={ROUTES.NEW_POST}>Create New Post</Link>
 
        {loading && <div>Loading ...</div>}
 
        <PostsList posts={posts} />
      </div>
    );
  } 
}
 

const PostsList = ({ posts }) => (
  <div className="post_list">
    {posts.map(post => (
      <SmallPost key={post.pid} post={post}/>
    ))}
  </div>
);

export default withFirebase(HomePage);
